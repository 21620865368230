// Module
var code = `<span class="token keyword">import</span> Map <span class="token keyword">from</span> <span class="token string">"ol/Map.js"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> TileLayer <span class="token keyword">from</span> <span class="token string">"ol/layer/Tile.js"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> View <span class="token keyword">from</span> <span class="token string">"ol/View.js"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> VectorTileLayer <span class="token keyword">from</span> <span class="token string">"ol/layer/VectorTile"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> TileDebug <span class="token keyword">from</span> <span class="token string">"ol/source/TileDebug"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> <span class="token punctuation">{</span> fromLonLat <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">"ol/proj"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> Style <span class="token keyword">from</span> <span class="token string">"ol/style/Style"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> <span class="token punctuation">{</span> FeatureLike <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">"ol/Feature"</span><span class="token punctuation">;</span>

<span class="token keyword">import</span> <span class="token punctuation">{</span> importMBTiles<span class="token punctuation">,</span> MBTilesVectorSource <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">"ol-mbtiles"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> <span class="token punctuation">{</span>
  waterStyle<span class="token punctuation">,</span>
  roadStyle<span class="token punctuation">,</span>
  buildingStyle<span class="token punctuation">,</span>
  boundaryStyle<span class="token punctuation">,</span>
  placeStyle<span class="token punctuation">,</span>
<span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">"../style"</span><span class="token punctuation">;</span>

<span class="token comment">// MBTiles from</span>
<span class="token comment">// https://data.maptiler.com/downloads/dataset/osm/europe/</span>
<span class="token comment">// 34.4 GB original file</span>
<span class="token comment">// down to 19.2GB after vacuum (MapTiler, wtf?)</span>

<span class="token keyword">export</span> <span class="token keyword">default</span> <span class="token keyword">async</span> <span class="token keyword">function</span> <span class="token punctuation">(</span><span class="token punctuation">)</span> <span class="token punctuation">{</span>
  <span class="token keyword">return</span> <span class="token keyword">new</span> <span class="token class-name">Map</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
    target<span class="token operator">:</span> <span class="token string">"map"</span><span class="token punctuation">,</span>
    layers<span class="token operator">:</span> <span class="token punctuation">[</span>
      <span class="token keyword">new</span> <span class="token class-name">TileLayer</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
        source<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name">TileDebug</span><span class="token punctuation">(</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token keyword">new</span> <span class="token class-name">VectorTileLayer</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
        source<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name">MBTilesVectorSource</span><span class="token punctuation">(</span>
          <span class="token keyword">await</span> <span class="token function">importMBTiles</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
            url<span class="token operator">:</span> <span class="token string">"https://velivole.b-cdn.net/maptiler-osm-2017-07-03-v3.6.1-europe.mbtiles"</span><span class="token punctuation">,</span>
            layers<span class="token operator">:</span> <span class="token punctuation">[</span>
              <span class="token string">"transportation"</span><span class="token punctuation">,</span>
              <span class="token string">"water"</span><span class="token punctuation">,</span>
              <span class="token string">"waterway"</span><span class="token punctuation">,</span>
              <span class="token string">"landuse"</span><span class="token punctuation">,</span>
              <span class="token string">"place"</span><span class="token punctuation">,</span>
              <span class="token string">"boundary"</span><span class="token punctuation">,</span>
            <span class="token punctuation">]</span><span class="token punctuation">,</span>
            <span class="token comment">// All properties from the MBTiles data can be overridden</span>
            attributions<span class="token operator">:</span> <span class="token punctuation">[</span>
              <span class="token string">"MapTiler"</span><span class="token punctuation">,</span>
              <span class="token string">"OSM"</span><span class="token punctuation">,</span>
              <span class="token string">"https://data.maptiler.com/downloads/dataset/osm/europe/"</span><span class="token punctuation">,</span>
            <span class="token punctuation">]</span><span class="token punctuation">,</span>
          <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
        <span class="token punctuation">)</span><span class="token punctuation">,</span>
        <span class="token function-variable function">style</span><span class="token operator">:</span> <span class="token keyword">function</span> <span class="token punctuation">(</span>feature<span class="token operator">:</span> FeatureLike<span class="token punctuation">)</span><span class="token operator">:</span> Style <span class="token punctuation">{</span>
          <span class="token keyword">switch</span> <span class="token punctuation">(</span>feature<span class="token punctuation">.</span><span class="token function">get</span><span class="token punctuation">(</span><span class="token string">"layer"</span><span class="token punctuation">)</span><span class="token punctuation">)</span> <span class="token punctuation">{</span>
            <span class="token keyword">case</span> <span class="token string">"water"</span><span class="token operator">:</span>
            <span class="token keyword">case</span> <span class="token string">"waterway"</span><span class="token operator">:</span>
              <span class="token keyword">return</span> waterStyle<span class="token punctuation">;</span>
            <span class="token keyword">case</span> <span class="token string">"transportation"</span><span class="token operator">:</span>
              <span class="token keyword">return</span> <span class="token function">roadStyle</span><span class="token punctuation">(</span>feature<span class="token punctuation">)</span><span class="token punctuation">;</span>
            <span class="token keyword">case</span> <span class="token string">"landuse"</span><span class="token operator">:</span>
              <span class="token keyword">return</span> buildingStyle<span class="token punctuation">;</span>
            <span class="token keyword">case</span> <span class="token string">"boundary"</span><span class="token operator">:</span>
              <span class="token keyword">return</span> boundaryStyle<span class="token punctuation">;</span>
            <span class="token keyword">case</span> <span class="token string">"place"</span><span class="token operator">:</span>
              <span class="token keyword">return</span> <span class="token function">placeStyle</span><span class="token punctuation">(</span>feature<span class="token punctuation">)</span><span class="token punctuation">;</span>
            <span class="token keyword">default</span><span class="token operator">:</span>
              <span class="token keyword">return</span> <span class="token keyword">null</span> <span class="token keyword">as</span> <span class="token builtin">unknown</span> <span class="token keyword">as</span> Style<span class="token punctuation">;</span>
          <span class="token punctuation">}</span>
        <span class="token punctuation">}</span><span class="token punctuation">,</span>
      <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
    <span class="token punctuation">]</span><span class="token punctuation">,</span>
    view<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name">View</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
      center<span class="token operator">:</span> <span class="token function">fromLonLat</span><span class="token punctuation">(</span><span class="token punctuation">[</span><span class="token number">12</span><span class="token punctuation">,</span> <span class="token number">50</span><span class="token punctuation">]</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      zoom<span class="token operator">:</span> <span class="token number">6</span><span class="token punctuation">,</span>
    <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
  <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">;</span>
<span class="token punctuation">}</span>
`;
// Exports
export default code;